import React, { useState, useEffect } from 'react';
import { Avatar, Button, Row } from 'antd';
import { Link, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
//import Message from './message';
import ShoppingIcon from './ShoppingIcon';
import HomeIcon from './HomeIcon';
//import Notification from './notification';
//import Settings from './settings';
import Support from './support';
import { Popover } from '../../popup/popup';
import { Dropdown } from '../../dropdown/dropdown';
import { logOut } from '../../../redux/authentication/actionCreator';
import { getCartCount } from '../../../redux/cartCount/actionCreator';
import { fbAuthLogout } from '../../../redux/firebase/auth/actionCreator';
import Heading from '../../heading/heading';
import FbSignIn from '../../../../src/container/profile/authentication/overview/FbSignIn';
import axios from 'axios';
import CreateProject from '../../../../src/container/project/overview/CreateProject';
import Cookies from 'js-cookie';
import '../../../../src/static/css/common.css';
//home/this/PythonDashboards/client/src/static/css/common.css

//import {ShoppingCartOutlined, HomeOutlined, TagsOutlined} from '@ant-design/icons';

function AuthInfo() {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();

  const { isAuthenticate } = useSelector(state => {
    return {
      isAuthenticate: state.fb.auth.uid
    };
  });
  const location = useLocation();
  const [isHomePage, setIsHomePage] = useState(false);
  useEffect(() => {
    // Function to update your flag or perform actions when route changes
    const updateFlagOnRouteChange = () => {
      if (location.pathname == '/') {
        setIsHomePage(true);
      } else {
        setIsHomePage(false);
      }
    };

    updateFlagOnRouteChange(); // Call function whenever route changes
  }, [location]);

  const [state, setState] = useState({
    flag: 'english',
    visible: false,
    user_name: '',
    profile_pic: '',
    role: null,
    first_letter: null,
    last_letter: null,
    user_id: null
  });


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/get-organization/${isAuthenticate}`)
      .then((response) => {
        let logo_array = null;
        var profile_pic_path = null;

        if (response.data.logo) {
          logo_array = JSON.parse(response.data.logo);
          console.log('logo_array', logo_array);
          if (logo_array != null) {
            profile_pic_path = logo_array[0].url;
          }
        }
        if (response.data) {
          var FL = "";
          var LL = "";
          if (response.data.name) {
            var name = response.data.name;
            FL = name.toUpperCase().charAt(0);
            if (name.toUpperCase().split(" ")[1]) {
              LL = name.toUpperCase().split(" ")[1].charAt(0);
            }
          }
          setState({
            ...state,
            user_name: response.data.name,
            role: response.data.role,
            profile_pic: profile_pic_path,
            first_letter: FL,
            last_letter: LL,
            user_id: response.data.id
          });
        }

      })
      .catch((error) => {
        console.log("Error", error)
      })
    if (getCartCount) {
      dispatch(getCartCount());
    }

  }, []);


  const showModal = () => {
    setState({
      ...state,
      visible: true,
    });
  };

  const onCancel = () => {
    setState({
      ...state,
      visible: false,
    });
  };

  const { flag, visible } = state;
  /*
  const SignOut1 = e => {
    e.preventDefault();
    if(localStorage.getItem('donate_item')!=null){
      localStorage.removeItem('donate_item');
    }
    if(localStorage.getItem('donate_amount')!=null){
      localStorage.removeItem('donate_amount');
    }
    //SignOut2();
  }
  */

  const SignOut = e => {
    e.preventDefault();
    Cookies.remove('logedIn');
    localStorage.removeItem('donate_item');
    localStorage.removeItem('donate_item_packages');
    localStorage.removeItem('donate_amount');
    if (isAuthenticate) {
      dispatch(fbAuthLogout());
    } else {
      dispatch(logOut());
    }
    //window.location.reload();
    setTimeout(() => {
      history.push('/');
      window.location.reload();
    }, 1500);

  };




  /*
  const getcart = e => {
    e.preventDefault();
    if(isAuthenticate)
    {
      history.push('/admin/ecommerce/cart');
    }else{
      history.push('/check-out');
    }
  }
  const gotoHome=()=>{
    history.push('/');
  }
  */

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          {
            state.profile_pic != null ?
              <>
                <img
                  // src={process.env.REACT_APP_LOGO_URL + state.profile_pic}
                  src={state.profile_pic}
                  width="45px" height="45px"
                  alt="" />
              </>
              :
              <><span className='hamburger-circles-dot'>
                {state.first_letter != null ? state.first_letter : ""}{state.last_letter != null ? state.last_letter : ""}
              </span>
              </>
          }

          <figcaption>
            <Heading as="h5">{state.user_name}</Heading>
            <p>{state.role}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to={`/dashboard`}>
              <FeatherIcon icon="home" /> Dashboard
            </Link>
          </li>
          <li>
            <Link to={`/profile/myProfile/overview`}>
              <FeatherIcon icon="settings" /> Manage My Profile
            </Link>
          </li>
          <li>
            <Link to={`/monthly-recurring-donations`}>
              <FeatherIcon icon="globe" /> Monthly Recurring
            </Link>
          </li>
          {/* <li>
            <Link to="#">
              <FeatherIcon icon="dollar-sign" /> Billing
            </Link>
          </li> */}
          {isAuthenticate === process.env.REACT_APP_ADMIN_UID ?
            <>
              <li>
                <Link to={`/list-contact-us`}>
                  <FeatherIcon icon="globe" /> Contact List
                </Link>
              </li>

              <li>
                <Link to={`/plans-list`}>
                  <FeatherIcon icon="globe" /> Plans List
                </Link>
              </li>
              <li>
                <Link to={`/donation-package-list`}>
                  <FeatherIcon icon="globe" /> Donation Packages
                </Link>
              </li>

              <li>
                <Link to={`/list-fund-initiative`}>
                  <FeatherIcon icon="globe" /> Fund Initiative
                </Link>
              </li>
              <li>
                <Link to={`/list-letter-campaigning`}>
                  <FeatherIcon icon="mail" /> Letter Campaigning
                </Link>
              </li>
              <li>
                <Link to={`/invite-organization`}>
                  <FeatherIcon icon="globe" /> Invite Organization
                </Link>
              </li>
              <li>
                <Link to={`/list-feeds`}>
                  <FeatherIcon icon="arrow-down" /> Manage Feeds
                </Link>
              </li>
              <li>
                <Link to={`/list-events`}>
                  <FeatherIcon icon="arrow-down" /> Manage Events
                </Link>
              </li>
              <li>
                <Link to={`/org/view/list`}>
                  <FeatherIcon icon="list" /> Manage Org
                </Link>
              </li>
              <li>
                <Link to={`/contents`}>
                  <FeatherIcon icon="layout" /> Manage Content
                </Link>
              </li>
              <li>
                <Link to={`/manage-campaigning`}>
                  <FeatherIcon icon="mail" /> Manage Campaigning
                </Link>
              </li>
              <li>
                <Link to={`/list-campaigning`}>
                  <FeatherIcon icon="send" /> Campaigning History
                </Link>
              </li>
              <li>
                <Link to={`/manage-settings`}>
                  <FeatherIcon icon="key" /> Manage Settings
                </Link>
              </li>
              <li>
                <Link to={`/org/view/grid`}>
                  <FeatherIcon icon="grid" /> Donate
                </Link>
              </li>
              <li>
                <Link to={`/users/sent-donations/sent/${state.user_id}/${state.role}`}>
                  <FeatherIcon icon="list" /> Donations Sent
                </Link>
              </li>
              <li>
                <Link to={`/users/donations/received/${state.user_id}/${state.role}`}>
                  <FeatherIcon icon="list" /> Donations Received
                </Link>
              </li>

            </>
            :
            <>
              <li>
                <Link to={`/org/view/grid`}>
                  <FeatherIcon icon="grid" /> Donate
                </Link>
              </li>

              {state.role === "organization" ?
                <>
                  <li>
                    <li>
                      <Link to={`/list-fund-initiative`}>
                        <FeatherIcon icon="globe" /> Fund Initiative
                      </Link>
                    </li>
                    <li>
                      <Link to={`/list-letter-campaigning`}>
                        <FeatherIcon icon="mail" /> Letter Campaigning
                      </Link>
                    </li>
                    <Link to={`/invite-organization`}>
                      <FeatherIcon icon="globe" /> Invite Organization
                    </Link>
                  </li>
                  <li>
                    <Link to={`/list-feeds`}>
                      <FeatherIcon icon="arrow-down" /> Manage Feeds
                    </Link>
                  </li>
                  <li>
                    <Link to={`/list-events`}>
                      <FeatherIcon icon="arrow-down" /> Manage Events
                    </Link>
                  </li>
                  <li>
                    <Link to={`/users/sent-donations/sent/${state.user_id}/${state.role}`}>
                      <FeatherIcon icon="list" /> Donations Sent
                    </Link>
                  </li>
                  <li>
                    <Link to={`/users/donations/received/${state.user_id}/${state.role}`}>
                      <FeatherIcon icon="list" /> Donations Received
                    </Link>
                  </li>
                  <li>
                    <Link to={`/manage-campaigning`}>
                      <FeatherIcon icon="mail" /> Manage Campaigning
                    </Link>
                  </li>
                  <li>
                    <Link to={`/list-campaigning`}>
                      <FeatherIcon icon="send" /> Campaigning History
                    </Link>
                  </li>
                  <li>
                    <Link to={`/manage-settings`}>
                      <FeatherIcon icon="key" /> Manage Settings
                    </Link>
                  </li>
                </>
                :
                <>
                  <li>
                    <Link to={`/users/sent-donations/sent/${state.user_id}/${state.role}`}>
                      <FeatherIcon icon="list" /> Donations Sent
                    </Link>
                  </li>
                </>
              }

              {/* <li>
                <Link to={`${path}/users/donations`}>
                  <FeatherIcon icon="list" /> Your Donations
                </Link>
              </li> */}
            </>
          }
          {/* <li>
            <Link to="#">
              <FeatherIcon icon="settings" /> Settings
            </Link>
          </li> */}

          {/* <li>
            <Link to="#">
              <FeatherIcon icon="users" /> Activity
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="bell" /> Help
            </Link>
          </li> */}
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = value => {
    setState({
      ...state,
      flag: value,
    });
  };

  const country = (
    <NavAuth>
      <Link onClick={() => onFlagChangeHandle('english')} to="#">
        <img src={require('../../../static/img/flag/english.png')} alt="" />
        <span>English</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('germany')} to="#">
        <img src={require('../../../static/img/flag/germany.png')} alt="" />
        <span>Germany</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('spain')} to="#">
        <img src={require('../../../static/img/flag/spain.png')} alt="" />
        <span>Spain</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('turky')} to="#">
        <img src={require('../../../static/img/flag/turky.png')} alt="" />
        <span>Turky</span>
      </Link>
    </NavAuth>
  );

  return (
    <InfoWraper>

      {/* <div className="notification" onClick={getcart}>
        <Badge count={cartcount ? cartcount : '1'} >
          <Link to="#" className="head-example">
            <FeatherIcon icon="shopping-cart" size={20} />
          </Link>
        </Badge>
      </div> */}
      {
        !isHomePage &&
        <div className="display_shopping_icon_desktop">
          <ShoppingIcon />
        </div>
      }



      {/* {!isAuthenticate && <HomeIcon />} */}

      {isAuthenticate &&
        <>
          {/* <Message />
          <Notification />
          <Settings /> 
          <Support />
          */}


          {/* <div className="nav-author">
            <Dropdown placement="bottomRight" content={country} trigger="click">
              <Link to="#" className="head-example">
                <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
              </Link>
            </Dropdown>
          </div> */}
        </>
      }

      <div className="nav-author">

        {isAuthenticate != undefined ?
          <>
            <Row className='hide_header_desktop' style={{ marginBottom: 20 }}>
              <Link to="/fundunity-items" className="head-example" >
                <FeatherIcon icon="home" size={22} />
              </Link>
              <Link to="/fundunity-latest-organizations" className="head-example" >
                <FeatherIcon icon="list" size={22} />
              </Link>
              <Link to="/news" className="head-example" >
                <FeatherIcon icon="globe" size={22} />
              </Link>
              <Link to="/donation-package" className="head-example" >
                <FeatherIcon icon="gift" size={22} />
              </Link>
              <Link to="/fund-initiatives" className="head-example" >
                <FeatherIcon icon="list" size={22} />
              </Link>
              <Link to="/letter-campaigning" className="head-example" >
                <FeatherIcon icon="mail" size={22} />
              </Link>
              <Link to="/events" className="head-example">
                <FeatherIcon icon="calendar" size={22} />
              </Link>
              <Link to="/map-listings" className="head-example">
                <FeatherIcon icon="map-pin" size={22} />
              </Link>
            </Row>
            <Popover placement="bottomRight" content={userContent} action="click">
              <Link to="#" className="head-example">
                {/* <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" /> */}
                <span className='header-circles-dot'>
                  {state.first_letter != null ? state.first_letter : ""}{state.last_letter != null ? state.last_letter : ""}
                </span>
              </Link>
            </Popover>
          </>

          :
          <div>
            <Row className='hide_header_desktop' style={{ marginBottom: 20 }}>
              <Link to="/fundunity-items" className="head-example" >
                <FeatherIcon icon="home" size={22} />
              </Link>
              <Link to="/fundunity-latest-organizations" className="head-example" >
                <FeatherIcon icon="list" size={22} />
              </Link>
              <Link to="/news" className="head-example" >
                <FeatherIcon icon="globe" size={22} />
              </Link>
              <Link to="/donation-package" className="head-example" >
                <FeatherIcon icon="gift" size={22} />
              </Link>
              <Link to="/fund-initiatives" className="head-example" >
                <FeatherIcon icon="list" size={22} />
              </Link>
              <Link to="/letter-campaigning" className="head-example" >
                <FeatherIcon icon="mail" size={22} />
              </Link>
              <Link to="/events" className="head-example">
                <FeatherIcon icon="calendar" size={22} />
              </Link>
              <Link to="/map-listings" className="head-example">
                <FeatherIcon icon="map-pin" size={22} />
              </Link>
            </Row>
            <Row>
              <Button onClick={showModal} shape="round" size="default" type="primary" style={{ marginLeft: '9px' }}>
                LOG IN/SIGN UP
              </Button>
            </Row>
          </div>


        }
      </div>
      <FbSignIn onCancel={onCancel} visible={visible} />
      {/* <CreateProject onCancel={onCancel} visible={visible} flag={0} /> */}
    </InfoWraper>
  );
}

export default AuthInfo;
