import { Input, Col, Row, Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import ImageCombiner from './ImageCombiner';
import { getCartCount } from '../../../redux/cartCount/actionCreator';

const DonationPackageList = () => {
    const history = useHistory();
    const [donationPackageData, setDonationPackageData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const [warningModal, setWarningModal] = useState(false);
    const dispatch = useDispatch();
    const [packageName, setPackageName] = useState("");
    const [packageDesc, setPackageDesc] = useState("");
    const [packageAmount, setPackageAmount] = useState("");
    const [packageLogos, setPackageLogos] = useState("");

    useEffect(() => {
        // Load cart items from local storage when the component mounts
        const savedCartItems = localStorage.getItem('donate_item_packages')
            ? JSON.parse(localStorage.getItem('donate_item_packages'))
            : [];
        setCartItems(savedCartItems);

        axios.get(`${process.env.REACT_APP_API_URL}/get-active-fundunity-donation-package`)
            .then(function (response) {
                if (response.data.length > 0) {
                    const initialData = response.data.map(item => {
                        // Find if the item is already in the cart
                        const savedItem = savedCartItems.find(cartItem => cartItem.id === item.package_id);
                        return {
                            ...item,
                            count: savedItem ? savedItem.count : 1 // Initialize count for each item
                        };
                    });

                    setDonationPackageData(initialData.slice(0, 10));
                    setSearchData(initialData.slice(0, 10));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const addToCart = (id, name, amount, organisations, count) => {
        let updatedCart = [...cartItems];
        const existingItemIndex = updatedCart.findIndex(el => el.id === id);

        if (existingItemIndex > -1) {
            updatedCart[existingItemIndex].count = count;
        } else {
            let organisations_list = JSON.stringify(JSON.parse(organisations)).replace(/\"/g, "'");
            const newItem = { id, name, amount, organisations_list, count };
            updatedCart.push(newItem);
        }

        setCartItems(updatedCart);
        localStorage.setItem('donate_item_packages', JSON.stringify(updatedCart));
        if (getCartCount) {
            dispatch(getCartCount());
        }
    };

    const removeFromCart = (id) => {
        const updatedCart = cartItems.filter(item => item.id !== id);
        setCartItems(updatedCart);
        localStorage.setItem('donate_item_packages', JSON.stringify(updatedCart));

        // Reset count to 1 for the item in the data
        const updatedData = searchData.map(item =>
            item.package_id === id ? { ...item, count: 1 } : item
        );
        setSearchData(updatedData);
        setDonationPackageData(updatedData);
        if (getCartCount) {
            dispatch(getCartCount());
        }
    };

    const searchRecords = (searchText) => {
        const filteredData = donationPackageData.filter(item =>
            item.description.toLowerCase().includes(searchText.toLowerCase()) ||
            item.name.toLowerCase().includes(searchText.toLowerCase())
        );
        setSearchData(filteredData);
    };

    const updateCount = (id, newCount) => {
        const updatedData = searchData.map(item =>
            item.package_id === id ? { ...item, count: newCount } : item
        );
        setSearchData(updatedData);
        setDonationPackageData(updatedData);
    };

    const showDetailsModal = (id, name, description, amount, logos) => {
        setPackageName(name);
        setPackageDesc(description)
        setPackageAmount(amount)
        setPackageLogos(logos)
        setWarningModal(true)
    };
    const handleModalCancel = () => {
        setWarningModal(false)
        setPackageName("");
        setPackageDesc("")
        setPackageAmount("")
        setPackageLogos("")
    };
    const goToAll = () => {
        history.push(`/all-donation-package`);
    }

    return (
        <div style={{ width: '100%' }}>
            <div style={{ marginTop: 10, paddingBottom: 60 }}>
                <Row style={{ marginRight: 10, marginLeft: 10 }} className='set-homepage-content'>
                    <Col xs={0} sm={0} md={2} lg={2}></Col>
                    <Col xs={24} sm={24} md={20} lg={20}>
                        <Row justify="center" style={{ margin: '10px 0' }}>
                            <Col span={24} md={12}>
                                <h2 className='item-container-title'>Donation Packages</h2>
                            </Col>
                            <Col span={24} md={12}>
                                <Input type='text' placeholder='Search here' onChange={(e) => { searchRecords(e.target.value) }} />
                            </Col>
                        </Row>
                        <Row style={{ margin: '15px 0', justifyContent: 'right', color: 'blue' }}>
                            <b style={{ cursor: 'pointer' }} onClick={goToAll}>See More</b>
                        </Row>
                        {
                            [...searchData].length > 0 ?
                                <div>
                                    <div className="item-scroll">
                                        {searchData.map((item) => {
                                            if (item) {
                                                const isInCart = cartItems.some(cartItem => cartItem.id === item.package_id);
                                                console.log("item.logos", item.logos)
                                                return (
                                                    <div className="item-card" key={item.package_id}>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='add-padding-desktop' style={{ textAlign: 'right', padding: '30px 2px',minHeight: 200 }}>
                                                            <ImageCombiner imageUrls={item.logos} />
                                                        </Col>
                                                        <h3 className="item-title">{item.name}</h3>
                                                        <p className="item-host">
                                                            <div className="entry-excerpt" style={{ display: 'block', marginBottom: 10 }} dangerouslySetInnerHTML={{ __html: item.description.substring(0, 150) + " ..." || "" }}>
                                                            </div>
                                                        </p>
                                                        <p className="item-location"><b style={{ fontSize: 23 }}>${item.amount}</b></p>

                                                        <button onClick={() => showDetailsModal(item.package_id, item.name, item.description, item.amount, item.logos)} className="n-item-button">More</button>

                                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                                                            <Button
                                                                onClick={() => updateCount(item.package_id, Math.max(1, item.count - 1))}
                                                                disabled={isInCart}
                                                            >
                                                                -
                                                            </Button>
                                                            <span style={{ margin: '0 10px' }}>{item.count}</span>
                                                            <Button
                                                                onClick={() => updateCount(item.package_id, item.count + 1)}
                                                                disabled={isInCart}
                                                            >
                                                                +
                                                            </Button>
                                                            {isInCart ? (
                                                                <Button size='default' type="danger" onClick={() => removeFromCart(item.package_id)} style={{ cursor: 'pointer', marginLeft: 10 }}>
                                                                    Remove from Cart
                                                                </Button>
                                                            ) : (
                                                                <Button size='default' type="primary" onClick={() => {
                                                                    addToCart(item.package_id, item.name, item.amount, item.organisations, item.count);
                                                                }} style={{ cursor: 'pointer', marginLeft: 10 }}>
                                                                    Add to Cart
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })}
                                        <div className="item-card see-more-card">
                                            <div className="see-more-content">
                                                <button onClick={goToAll} className="see-more-button">See more</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <Row style={{ justifyContent: 'center' }}>
                                    <h2 className='item-container-title'>Not Found</h2>
                                </Row>
                        }

                    </Col>
                    <Col xs={0} sm={0} md={2} lg={2}></Col>
                </Row>
            </div>
            <Modal
                type="primary"
                title="Package Details"
                width={"80%"}
                open={warningModal}
                footer={null}
                onCancel={handleModalCancel}
            >
                <div className="project-modal">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Row style={{ justifyContent: 'center' }}>
                            <ImageCombiner imageUrls={packageLogos} />
                        </Row>
                        <Row style={{ justifyContent: 'center' }}>
                            {/* <p style={{ fontFamily: "Melodrama", fontSize: 35, marginBottom: 0 }} align="center">{packageName}</p> */}
                            <h2 className='item-container-title'>{packageName}</h2>
                        </Row>
                        <Row style={{ justifyContent: 'center' }}>
                            <p style={{ fontFamily: "Arial", fontSize: 20, fontStyle: 'italic', marginBottom: 0 }} align="center">
                                <div class="entry-meta">
                                    {/* <span class="entry-date">${packageAmount}</span> */}
                                    <p className="item-location"><b style={{ fontSize: 23 }}>${packageAmount}</b></p>

                                </div>
                            </p>
                        </Row><br />
                        <Row style={{ justifyContent: 'center' }}>
                            <p style={{ fontFamily: "Arial", fontSize: 15, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: packageDesc != "" ? packageDesc : "" }}></p>
                        </Row>
                    </Col>
                </div>
            </Modal>
        </div>
    );
};

export default DonationPackageList;
