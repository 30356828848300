import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, InputNumber, Select, Col, Row, Upload, TimePicker, message, Button, DatePicker } from 'antd';
import propTypes from 'prop-types';
import { headerSearchAction } from '../../../redux/headerSearch/actionCreator';
import { Modal } from '../../../components/modals/antd-modals';
import { FormValidationWrap } from '../../forms/overview/Style';
import axios from 'axios';
import moment from 'moment';


const { TextArea } = Input;
const { Option } = Select;

const EditFundunityPlan = ({ visible, Id, onCancel, editData }) => {
    const dateFormat = 'MM/DD/YYYY';

    const dispatch = useDispatch();

    const { isAuthenticate } = useSelector(state => {
        return {
            isAuthenticate: state.fb.auth.uid
        };
    });
    useEffect(() => {
        if (headerSearchAction) {
            dispatch(headerSearchAction(1));
        }
    }, [dispatch]);

    const [form] = Form.useForm();
    const formData = new FormData();
    const [ShowForm, setShowForm] = useState(false);

    const searchData = useSelector(state => state.headerSearchData);
    const [filteredOrgs, setFilteredOrgs] = useState([]);

    const [state, setState] = useState({
        visible,
        modalType: 'primary',
        isLoading: false
    });

    useEffect(() => {
        if (editData) {
            try {
                getElementData();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

    }, [Id]);

    useEffect(() => {
        if (searchData) {
            setFilteredOrgs(searchData)
        }
    }, [searchData]);


    const handleDropdownChange = (value, name) => {
        // return;
        setState({
            ...state,
            [name]: value,
        });
        if (name === 'cause') {
            const filtered = value.length
                ? searchData.filter(org => {
                    const causes = org.cause ? JSON.parse(org.cause) : [];
                    return causes.some(cause => value.includes(cause));
                })
                : searchData;

            // Update the filtered organizations and reset the organizations field
            setFilteredOrgs(filtered);
            form.setFieldsValue({ organisations: [] });
        }
    }

    const getElementData = async () => {
        try {
            setShowForm(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            setState({
                visible,
            });
        }
        return () => {
            unmounted = true;
        };
    }, [visible]);
    const [buttonDisable, setButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [UID, setOrgUID] = useState(null);
    const onSelect = (selectedItems) => {
        console.log("selectedItems :", selectedItems);
        setOrgUID(selectedItems);
    }
    const handleOk = (values) => {
        let post_data = {
            id: editData.id,
            ...values,
        };

        if (isAuthenticate == process.env.REACT_APP_ADMIN_UID) {
            post_data = {
                uid: isAuthenticate,
                id: editData.id,
                ...values,
            };
        }
        setButton(true);
        setLoading(true);

        setState({
            ...state,
            isLoading: true,
        });
        console.log(post_data);
        const formData = new FormData();
        formData.append('data', JSON.stringify(post_data))

        setButton(true);
        setLoading(true);
        setState({
            ...state,
            isLoading: true,
        });

        axios.post(`${process.env.REACT_APP_API_URL}/update-fundunity-recurring-donation`, formData)
            .then((response) => {
                if (response.data.status == true) {
                    alert("The fundunity donation package updated successfully.");
                    window.location.reload();
                    //onCancel();
                } else {
                    setButton(false);
                    setLoading(false);
                    alert("Uanble to update the donation package");
                }
            })
            .catch((error) => {
                console.log("Error :", error)
            })

    };
    const handleCancel = () => {
        onCancel();
    };

    return (

        <>

            <Modal
                type={state.modalType}
                title="Edit Fundunity Monthly Recurring Donation Package"
                visible={state.visible}
                width={1000}
                backdrop="static"
                footer={[null]}
                onCancel={handleCancel}
            >
                <div className="project-modal">
                    <>
                        <FormValidationWrap>
                            {ShowForm == true &&
                                <>
                                    <Form form={form} name="sDash_validation-form" layout="vertical" onFinish={handleOk} encType="multipart/form-data">

                                        <Form.Item name="name" label="Name"
                                            rules={[{ required: true, message: 'Please input name' }]}
                                            initialValue={editData ? editData.name : ''}
                                        >
                                            <Input placeholder="Plan Name" name="name" />
                                        </Form.Item>

                                        <Form.Item name="description" label="Description"
                                            rules={[{ required: true, message: 'Please input description' }]}
                                            initialValue={editData ? editData.description : ''}
                                        >
                                            <TextArea rows={3} type='text' name='description' />
                                        </Form.Item>

                                        <Form.Item name="amount" label="Amount"
                                            rules={[{ required: true, message: 'Please input amount' }]}
                                            initialValue={editData ? editData.amount : ''}>
                                            <InputNumber style={{ width: 300 }} placeholder="Amount" type="number" name="amount" />
                                        </Form.Item>
                                        <Form.Item name="billing_date" label="Billing Date"
                                            rules={[{ required: true, message: 'Please input date' }]}
                                            initialValue={editData && editData.billing_date ? moment(editData.billing_date) : null}
                                        >
                                            <DatePicker format={dateFormat} />
                                        </Form.Item>
                                        <Form.Item name="cause" label="Cause"
                                            // rules={[{ required: true, message: 'Please input cause' }]}
                                            // initialValue={editData.cause ? JSON.parse(editData.cause) : ''}
                                        >
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode} mode="multiple"
                                                size="large" className="sDash_fullwidth-select" name="cause" placeholder="Select from dropdown"
                                                onChange={(value) => handleDropdownChange(value, 'cause')}
                                            >
                                                <Option key={1} value={"Cause 1"}>Cause 1</Option>
                                                <Option key={0} value={"Cause 2"}>Cause 2</Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="organisations" label="Organisations"
                                        //  rules={[{ required: true, message: 'Please select organizations' }]}
                                            //initialValue={orgData != undefined && orgData.organization_category != undefined && JSON.parse(orgData.organization_category)}
                                            // initialValue={editData.organisations ? JSON.parse(editData.organisations) : ''}
                                        >
                                            <Select style={{ width: '100%' }}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                size="large" mode="multiple" className="sDash_fullwidth-select"
                                            >
                                                {
                                                    filteredOrgs.length ? (
                                                        filteredOrgs.map(value => {

                                                            return (<Select.Option value={value.uid}>{value.title}</Select.Option>)
                                                        })) : ""}
                                            </Select>
                                        </Form.Item>

                                        {/* <Form.Item name="organisations" label=""
                                            //{...formItemLayout}
                                            style={{ marginRight: '5px' }}
                                        >
                                            <Select onChange={onSelect} placeholder="Select Organization" className='mutiple-select'>
                                                <Select.Option value={null}>All Organization</Select.Option>
                                                {
                                                    searchData.length ? (
                                                        searchData.map(value => {

                                                            return (<Select.Option value={value.uid}>{value.title}</Select.Option>)
                                                        })) : ""}
                                            </Select>
                                        </Form.Item> */}
                                        <Form.Item name="type" label="Type"
                                            rules={[{ required: true, message: 'Please input type' }]}
                                            initialValue={editData.type}
                                        >
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                size="large" className="sDash_fullwidth-select" name="type" placeholder="Select from dropdown"
                                                onChange={(value) => handleDropdownChange(value, 'type')}
                                            >
                                                <Option key={1} value={"Monthly"}>Monthly</Option>
                                                <Option key={0} value={"One Time"}>One Time</Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item name="status" label="Status"
                                            rules={[{ required: true, message: 'Please input status' }]}
                                            initialValue={Number(JSON.parse(editData.status))}
                                        >
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                size="large" className="sDash_fullwidth-select" name="status" placeholder="Select from dropdown"
                                                onChange={(value) => handleDropdownChange(value, 'status')}
                                            >
                                                <Option key={1} value={1}>Active</Option>
                                                <Option key={0} value={0}>Inactive</Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" disabled={buttonDisable}>
                                                Submit {loading && (<i className='fa fa-spinner fa-spin' style={{ fontSize: 18, marginLeft: 5 }} />)}
                                            </Button>
                                            {' '}
                                            <Button size="default" type="white" key="back" outlined onClick={handleCancel}>
                                                Cancel
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </>}
                        </FormValidationWrap>
                    </>

                </div>
            </Modal>
        </>
    );
};

EditFundunityPlan.propTypes = {
    visible: propTypes.bool.isRequired,
    onCancel: propTypes.func.isRequired,
};

export default EditFundunityPlan;


